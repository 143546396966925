<template lang="pug">
.om-modal.image-manager(@click="hide")
  .content(@click.stop="")
    .header
      .header-inner
        .om-row
          .om-col.om-col-12.header-content
            .om-title-image {{ $t('imageManager') }}
            .button.om-close(@click="hide") &times;
    .main(
      :class="{ 'no-img': !images.length }"
      @drop.prevent="onUploadDrop"
      @dragover.prevent="onDragOver"
      @dragleave.prevent="onDragLeave"
    )
      .upload-cloud-wrapper(:class="{ 'center-img': images.length > 0 }")
        i.fa.fa-cloud-upload
      .upload-area
        .upload-container
          .upload-cloud-wrapper(:class="{ 'no-img': images.length === 0 }")
            i.fa.fa-cloud-upload
          .button.button-large.upload-button.p-3(@click="openFileChooser").
            {{$t('imageUploadNew')}}
            #[input(type="file" multiple="multiple" accept=".jpeg,.jpg,.jpeg-2000,.png,.svg,.gif,.tiff,.webp,.avif" style="display: none" ref="fileInput" @change="onFileChange")]
          .upload-message.p-2 {{ $t('imageUploadMessage') }}
          .upload-size.upload-maxfile.p-2 {{ $t('imageMaxSize', { size: MAX_KBYTE, sizeMB: MAX_KBYTE / 1024 }) }}
          .image-replace-toggle(v-if="showReplaceToggle")
            om-switch#replaceImageToggle(
              :label="$t('imageManagerModals.library.toggle.label')"
              v-model.sync="imageReplace"
              :disabled="imageManagerLogoUpload"
            )
      .images.scrollable
        .image(v-if="newImageLoading")
          img(:src="require('@/assets/editor/svg/om_picture.svg')")
        .image(v-for="(i, index) in images")
          inline-svg(v-if="i.url.includes('.svg')" :src="i.url")
          om-progressive-image(
            v-else
            :src="`${i.url}?ts=${timestamp(i.updatedAt)}`"
            placeholder="/om_picture.svg"
            backgroundColor="transparent"
            :lazyLoad="true"
          )
          .image-hover
            .image-data
              .image-name {{ i.name }}
              .image-res {{ $t('dimensions') }}: {{ i.width }}x{{ i.height }}
            .card.text-center.m-2(v-if="isBlacklisted(i.url)")
              .card-box.p-1 {{ $t('imageCantUse', { ext: isBlacklisted(i.url) }) }}
            .button.button-primary.button-large.p-3(v-else @click="useImage(index)") {{ $t('imageUse') }}
            .button-group
              .button.button-large
                i.fa.fa-trash.fa-2x(@click="removeImage(index)")
      .upload-drop-message.pointer-events-none(v-show="dragover")
        i.fa.fa-plus.fa-5x
</template>
<script>
  import { mapGetters, mapMutations, mapState, mapActions } from 'vuex';
  import { logoTypeWhitelist } from '@om/template-properties/src/imageReplace';
  import { track } from '@/services/xray';
  import { calcImageInitialWidth, getSelectedImageHeight, isSvgUrl } from '@/editor/util';
  import { get as _get, setWith } from 'lodash-es';
  import { restClient as axios } from '@/editor/axios';
  import { client as apollo, gql } from '@/editor/apollo';
  import itemMixin from '@/editor/mixins/item';
  import imageReplaceMixin from '@/mixins/imageManager/imageReplace';

  const MAX_KBYTE = 4096;

  const frame = () => document.getElementById('workspaceFrame');
  const frameWindow = () => frame().contentWindow;
  const frameStore = () => frameWindow().om.store;

  export default {
    mixins: [itemMixin, imageReplaceMixin],
    data() {
      return {
        MAX_KBYTE,
        dragover: false,
        newImageLoading: false,
        canEditMobile: true,
        canUseNested: true,
        selectedPageEl: null,
      };
    },
    computed: {
      ...mapGetters(['columnInfoOfSelectedElement', 'isNano']),
      ...mapState([
        'imageManagerDestination',
        'images',
        'template',
        'imageDropColumn',
        'templateSaveData',
        'selectedElement',
        'selectedPath',
        'mobilePreview',
        'selectedPage',
        'themeKit',
        'imageManagerLogoUpload',
      ]),
      extensionBlacklist() {
        return this.imageManagerDestination.extBlacklist || [];
      },
    },
    watch: {
      selectedPage: {
        handler(v) {
          if (v && v.uid) {
            this.$nextTick(() => {
              this.selectedPageEl = frameWindow().document.getElementById(v.uid);
            });
          }
        },
        immediate: true,
      },
    },
    mounted() {
      this.loadImages();
    },
    methods: {
      ...mapMutations(['activatePaneL2', 'hideImageManager', 'setImageManagerLogoUpload']),
      ...mapActions(['changeImageOnPages']),
      openFileChooser() {
        this.$refs.fileInput.click();
      },
      timestamp(str) {
        return new Date(str).getTime();
      },
      onFileChange(e) {
        this.createImages(e.target.files);
      },
      onDragOver() {
        this.dragover = true;
      },
      onDragLeave() {
        this.dragover = false;
      },
      onUploadDrop(e) {
        this.dragover = false;
        this.createImages(e.dataTransfer.files);
      },
      createImages(files) {
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          if (file.type.match('^image/')) {
            this.createImage(file);
          } else {
            this.$notify({
              type: 'error',
              text: this.$t('notifications.unsupportedFileFormat'),
            });
          }
        }
      },
      createImage(file) {
        const self = this;
        const reader = new FileReader();
        reader.fileName = file.name;
        this.$bus.$emit('showAdminLoader', true);
        self.newImageLoading = true;
        reader.onload = (e) => {
          const url = e.target.result;
          const htmlImage = new Image();
          htmlImage.src = url;
          htmlImage.onload = function () {
            const kbytes = file.size / 1024;
            track('campaignImageUpload', { kbytes });
            if (kbytes > MAX_KBYTE) {
              const msg = self.$t('fileSizeToLargeMsg', { size: MAX_KBYTE });
              self.$bus.$emit('showAdminLoader', false);
              self.newImageLoading = false;
              // eslint-disable-next-line
              window.alert(msg);
              return;
            }
            const splitName = e.target.fileName.split('.');
            const name = `${splitName[0].substr(0, 17)}_${new Date().getTime()}.${splitName[1]}`;
            const data = new FormData();
            data.append('file', file, name);
            data.append('type', self.templateSaveData.type);
            data.append('value', self.templateSaveData.value);
            data.append('height', this.height);
            data.append('width', this.width);
            axios
              .post('upload/editor', data, { headers: { 'content-type': 'multipart/form-data' } })
              .then(() => {
                self.loadImages();
              })
              .catch((err) => {
                const text =
                  err.response && err.response.data && err.response.data.error
                    ? err.response.data.error
                    : this.$t('notifications.errorWhileUploadingImage');

                self.$notify({
                  type: 'error',
                  text,
                });
              });
          };
        };
        reader.readAsDataURL(file);
      },
      removeImage(index) {
        const image = this.images[index];
        this.$bus.$emit('showAdminLoader', true);
        axios
          .delete(`upload/editor/${image._id}`, {
            params: {
              name: image.name,
              saveType: this.templateSaveData.type,
              saveValue: this.templateSaveData.value,
            },
          })
          .then((result) => {
            const { success, useCount } = result.data;
            if (success) {
              this.loadImages();
            } else {
              // eslint-disable-next-line
              window.alert(`Image is used in ${useCount} campaigns`);
              this.$bus.$emit('showAdminLoader', false);
            }
          });
      },
      async useImage(index) {
        const isLogoUpload = this.imageManagerLogoUpload === true;

        // TODO:(gergogy) CLEAN UP NEXT TIME (hotfix)
        const { _id, url, width, height } = this.images[index];
        const name = this.getNameFromURL(url);
        let selectedImage = { imageId: _id, imageUrl: url };
        if (isLogoUpload) selectedImage = { ...selectedImage, width, height };
        const currentImage = { ...this.currentImage };

        if (isSvgUrl(url)) {
          await this.loadSvgInline(this.images[index]);
        }

        // handle logo replace
        if (this.imageManagerLogoUpload) {
          const currentImage = this.getLastLogoImage();

          this.changeImageOnPages({
            type: 'allPages',
            imageData: { currentImage, selectedImage },
            shouldResetVisibility: !this.themeKit?.logo?.current,
            whitelist: logoTypeWhitelist,
          });
          this.themeKit.logo.current = name;
          this.themeKit.logo.history?.push(name);
          this.$bus?.$emit?.('re-render-all-previews');
        }

        let {
          targetAttr,
          background,
          isLuckyWheel = false,
          simpleImage,
          saveImageUrl,
          editedDevice,
        } = this.imageManagerDestination;
        this.$nextTick(() => {
          if (isLogoUpload) return this.hide();
          const isDataAttr = targetAttr.includes('data');
          if (simpleImage) {
            const isStatePath = this.isStatePath(targetAttr);
            if (!this.isModalNeeded) {
              if (isStatePath) {
                targetAttr = targetAttr.replace('state.', '');
                setWith(this.$store.state, `${targetAttr}.imageId`, _id, (ns, key, target) => {
                  this.$set(target, key, ns);
                });
                setWith(this.$store.state, `${targetAttr}.imageUrl`, url, (ns, key, target) => {
                  this.$set(target, key, ns);
                });
              } else if (isDataAttr) {
                this.$store.commit('updateData', { property: `${targetAttr}.imageId`, value: _id });
                this.$store.commit('updateData', {
                  property: `${targetAttr}.imageUrl`,
                  value: url,
                });
              } else {
                this.$store.commit('updateStyle', {
                  property: `${targetAttr}.imageId`,
                  value: _id,
                });
                this.$store.commit('updateStyle', {
                  property: `${targetAttr}.imageUrl`,
                  value: url,
                });
              }
            }
            this.hide();
            this.showImageReplaceIfNeeded({ currentImage, selectedImage });
            return;
          }

          if (!editedDevice) {
            editedDevice = this.mobilePreview ? 'mobile' : 'desktop';
          }

          let propertyPrefix;
          if (
            targetAttr.includes('globalStyle') ||
            targetAttr.includes('subElements') ||
            targetAttr.includes('selectedPage')
          ) {
            propertyPrefix = targetAttr;
          } else {
            propertyPrefix = `${targetAttr}.${editedDevice}`;
          }

          // TODO CLEAN UP NEXT TIME (hotfix)
          // TODO [gergogy] simplify image manager
          if (!this.isModalNeeded) {
            if (
              targetAttr &&
              (targetAttr === 'globalStyle.overlay' || targetAttr === 'globalStyle.tab')
            ) {
              this.setValueOf(`${targetAttr}.background.imageId`, _id);
              if (saveImageUrl) {
                this.setValueOf(`${targetAttr}.background.imageUrl`, url);
              }
              this.setValueOf(`${targetAttr}.background.color`, null);
            } else if (targetAttr === 'selectedPage') {
              this.setValueOf(`${targetAttr}.desktop.background.imageId`, _id);
              this.setValueOf(`${targetAttr}.mobile.background.imageId`, _id);
              if (saveImageUrl) {
                this.setValueOf(`${targetAttr}.desktop.background.imageUrl`, url);
                this.setValueOf(`${targetAttr}.mobile.background.imageUrl`, url);
              }
              this.setValueOf(`${targetAttr}.desktop.background.color`, null); // reset background color
              this.setValueOf(`${targetAttr}.mobile.background.color`, null); // reset background color
              this.setValueOf(`${targetAttr}.desktop.background.type`, 'transparent', true);
              this.setValueOf(`${targetAttr}.mobile.background.type`, 'transparent', true);
            } else {
              this._handleMissingDesktopProperties(saveImageUrl, url, _id);

              this.setValueOf(`${propertyPrefix}.background.imageId`, _id);
              if (saveImageUrl || url) {
                this.setValueOf(`${propertyPrefix}.background.imageUrl`, url);
              }
              this.setValueOf(`${propertyPrefix}.background.color`, null); // reset background color
              this.setValueOf(`${propertyPrefix}.background.type`, 'transparent', true);
            }

            // if not adding element get current column info
            if (targetAttr === 'selectedElement' && background !== true) {
              const width = calcImageInitialWidth(this.selectedElement);
              const height = getSelectedImageHeight();

              this.setValueOf(`${propertyPrefix}.smartSize.width`, width);
              this.setValueOf(`${propertyPrefix}.smartSize.type`, 'manual');

              if (['OmImage', 'OmFloatingImage'].includes(this.selectedElement.type)) {
                this.setValueOf(`${targetAttr}.mobile.background.color`, null);
                this.setValueOf(`${targetAttr}.mobile.background.type`, 'transparent', true);
              }

              if (this.selectedElement.type === 'OmFloatingImage' && !this.mobilePreview) {
                let x = this.getValueOf(`${propertyPrefix}.position.left`);
                let y = this.getValueOf(`${propertyPrefix}.position.top`);

                x -= width / 2;
                y -= height / 2;

                const percentageX = (x / this.selectedPageEl.offsetWidth) * 100;
                const percentageY = (y / this.selectedPageEl.offsetHeight) * 100;

                this.setValueOf(`${propertyPrefix}.position.left`, percentageX);
                this.setValueOf(`${propertyPrefix}.position.top`, percentageY);
              }

              this.$bus.$emit('regenerateElementStyles', { type: this.selectedElement.type });

              // reset image pane on image change
              if (!isLuckyWheel) {
                this.activatePaneL2('ImagePane');
              }
            } else {
              this.setValueOf(`${propertyPrefix}.background.type`, 'image', true);
            }

            if (targetAttr.includes('Page')) {
              this.$bus.$emit('regenerateElementStyles', { type: 'OmPage' });
            }
          }

          this.hide();
          this.$bus.$emit('re-render-all-previews');
          this.showImageReplaceIfNeeded({ currentImage, selectedImage });
        });
      },
      _handleMissingDesktopProperties(saveImageUrl, url, _id) {
        // We need to set desktop image also when the user set the image firstly on mobile
        const hasDesktopValue = !!this.getValueOf('selectedElement.desktop.background.imageId');
        if (this.mobilePreview && hasDesktopValue) return;

        this.setValueOf(`selectedElement.desktop.background.imageId`, _id);
        if (saveImageUrl || url) {
          this.setValueOf(`selectedElement.desktop.background.imageUrl`, url);
        }
      },
      async loadSvgInline(image) {
        const { data: svg } = await axios.get(image.url, { withCredentials: false });

        frameStore().state.template.inlineSvgs = frameStore().state.template.inlineSvgs || {};
        frameStore().state.template.inlineSvgs[image._id] = svg;
        this.$store.state.inlineSvgs = this.$store.state.inlineSvgs || {};
        this.$store.state.inlineSvgs[image._id] = svg;
      },
      loadImages() {
        const { type, value } = this.templateSaveData;
        this.$bus.$emit('showAdminLoader', true);
        const templateQuery = gql`{images: allTemplateImages(template: "${value}"){_id, name, url, height, width}}`;
        const variantQuery = gql`
          {
            images: allImages {
              _id
              name
              url
              height
              width
              updatedAt
            }
          }
        `;
        apollo
          .query({
            query: type === 'base' ? templateQuery : variantQuery,
          })
          .then((r) => {
            const usedImages = this.template?.images || [];
            const frameStoreBaseImages = frameStore()?.state?.baseImages || [];
            const baseImages = [...frameStoreBaseImages, ...usedImages];
            const baseImageHash = {};
            baseImages.forEach((image) => {
              baseImageHash[image._id] = true;
            });
            const images = _get(r, 'data.images') || [];
            this.$bus.$emit('addImages', [
              ...baseImages,
              ...images.filter((image) => !baseImageHash[image._id]),
            ]);
            this.$nextTick(() => {
              this.$bus.$emit('showAdminLoader', false);
              this.newImageLoading = false;
            });
          });
      },
      isBlacklisted(url) {
        let blacklisted = null;
        this.extensionBlacklist.forEach((ext) => {
          if (url.endsWith(`.${ext}`)) blacklisted = ext;
        });
        return blacklisted;
      },
      hide() {
        const { addElement } = this.imageManagerDestination;

        let imageId = _get(this.selectedElement, 'desktop.background.imageId');

        this.setImageManagerLogoUpload(false);

        if (this.mobilePreview) {
          imageId = _get(this.selectedElement, 'mobile.background.imageId');
        }

        if (addElement && !imageId && this.selectedElement) {
          this.$bus.$emit('removeElement', { uid: this.selectedElement.uid });
        }
        this.hideImageManager();
      },
      getLastLogoImage() {
        const last =
          this.themeKit?.logo?.current ??
          this.themeKit?.logo?.history?.[this.themeKit?.logo?.history?.length - 1] ??
          null;

        if (!last) return;
        const image =
          this.images?.find?.(({ url }) => {
            return this.getNameFromURL(url) === this.getNameFromURL(last);
          }) ?? null;

        return image
          ? {
              imageId: image._id,
              imageUrl: image.url,
            }
          : null;
      },
    },
  };
</script>
